/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpContext,
  HttpHeaders,
  HttpParams
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  AncillaryIntervalDataSubmissionQueryResponse,
  AncillarySubmission,
  AvailabilityRequest,
  AvailabilityResponse,
  AwardsResponse,
  CopIntervalDataSubmissionQueryResponse,
  CopSubmission,
  RealTimeIntervalDataSubmissionQueryResponse,
  RealTimeSubmission,
  SubmissionStatus,
  TenaskaQueryData,
  TenaskaTransaction,
  VerificationRequest
} from '../schemas'



type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class TenaskaService {
  constructor(
    private http: HttpClient,
  ) {}/**
 * @summary Request Tenaska availability information for the target assets on specified date
 */
 postApiTenaskaAvailability<TData = AvailabilityResponse[]>(
    availabilityRequest: AvailabilityRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/tenaska/availability`,
      availabilityRequest,options
    );
  }
 postApiTenaskaAwards<TData = AwardsResponse[]>(
    availabilityRequest: AvailabilityRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/tenaska/awards`,
      availabilityRequest,options
    );
  }
/**
 *  Sample request:

     POST /QueryTenaskaData
{
 "startDate": "1/1/2024",
 "endDate": "1/31/2024",
 "queryType": "Ancillaries" | "COP" | "RealTime",
 "isLoad: "true" | "false" (ancillaries should be set to false)
}
 * @summary Query Tenaska for Data
 */
 postApiTenaskaQueryRaw<TData = void>(
    tenaskaQueryData: TenaskaQueryData, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/tenaska/query/raw`,
      tenaskaQueryData,options
    );
  }
 postApiTenaskaQuerySubmissionsAncillaries<TData = AncillaryIntervalDataSubmissionQueryResponse[]>(
    tenaskaQueryData: TenaskaQueryData, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/tenaska/query/submissions/ancillaries`,
      tenaskaQueryData,options
    );
  }
 postApiTenaskaQuerySubmissionsCop<TData = CopIntervalDataSubmissionQueryResponse[]>(
    tenaskaQueryData: TenaskaQueryData, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/tenaska/query/submissions/cop`,
      tenaskaQueryData,options
    );
  }
 postApiTenaskaQuerySubmissionsRealtime<TData = RealTimeIntervalDataSubmissionQueryResponse[]>(
    tenaskaQueryData: TenaskaQueryData, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/tenaska/query/submissions/realtime`,
      tenaskaQueryData,options
    );
  }
/**
 *  Sample request:
     POST / PostCOPSubmissionData
       {
    "submittedBy": "UserInterface",
    "resourceSubmission": [
        {
            "resource": "NOBLESLR_BESS1",
            "selecteddate": "2023-12-14T01:00:00.000Z",
            "intervals": [
                {
                    "hourEnding": 1,
                    "status": "EMR",
                    "lel": 2,
                    "lsl": 32,
                    "hsl": 2,
                    "hel": 222,
                    "rrs": 2,
                    "regUp": 5,
                    "regDown": 555,
                    "nonSpin": 5,
                    "ecrs": 57
                },
                {
                    "hourEnding": 2,
                    "status": "OFF",
                    "lel": 1,
                    "lsl": 13,
                    "hsl": 3,
                    "hel": 2,
                    "rrs": 5,
                    "regUp": 56,
                    "regDown": 7,
                    "nonSpin": 5,
                    "ecrs": 55
                },
            ],
        },
    ]
}
 * @summary POST Cop Submission Data
 */
 postApiTenaskaSubmitCop<TData = TenaskaTransaction[]>(
    copSubmission: CopSubmission, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/tenaska/submit/cop`,
      copSubmission,options
    );
  }
/**
 * Sample request (for real time bid):
    POST / PostRealTimeData
      {
   "submittedBy": "AI",
   "realTimeResource": [
       {
           "realTimeData": [{ 
                hourEnding: 1, 
                charge: [
                    {segment: 1, price: 10, volume: 10},
                    {segment: 2, price: 20, volume: 20}
                ],
           "resource": "NOBLESLR_LD1",
           "selectedDate": "2023-11-23T01:00:00.000Z",
           "isLoad":true
       },
       ...
   ]}
            
Sample request (for 3 part offer):
    POST / PostRealTimeData
      {
   "submittedBy": "AI",
   "realTimeResource": [
       {
           "realTimeData": [{ 
                hourEnding: 1, 
                discharge: [
                    {segment: 1, price: 10, volume: 10},
                    {segment: 2, price: 20, volume: 20}
                ],
           "resource": "NOBLESLR_BESS1",
           "selectedDate": "2023-11-23T01:00:00.000Z",
           "isLoad":false
       },
       ...
   ]}
 * @summary POST Real Time Energy Input or Three Part Offer for Load or Gen
 */
 postApiTenaskaSubmitRealtime<TData = TenaskaTransaction[]>(
    realTimeSubmission: RealTimeSubmission, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/tenaska/submit/realtime`,
      realTimeSubmission,options
    );
  }
/**
 *  Sample request:
 {
  "resourceSubmission": 
    [{
      "resource": "NOBLESLR_BESS1",
      "selectedDate": "2023-11-18T01:00:00.000Z",
      "intervals": [{
        "hourEnding": 1,
        "ecrs": {"price":10, "volume":100}
       },{
        "hourEnding":2,
        "rrs": {"price":10, "volume":100}
        }]
    }],
    "submittedBy": "AI"
}
 * @summary POST Day Ahead Ancillary Data
 */
 postApiTenaskaSubmitAncillaries<TData = TenaskaTransaction[]>(
    ancillarySubmission: AncillarySubmission, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/tenaska/submit/ancillaries`,
      ancillarySubmission,options
    );
  }
 postApiTenaskaVerify<TData = SubmissionStatus[]>(
    verificationRequest: VerificationRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/tenaska/Verify`,
      verificationRequest,options
    );
  }
};

export type PostApiTenaskaAvailabilityClientResult = NonNullable<AvailabilityResponse[]>
export type PostApiTenaskaAwardsClientResult = NonNullable<AwardsResponse[]>
export type PostApiTenaskaQueryRawClientResult = NonNullable<void>
export type PostApiTenaskaQuerySubmissionsAncillariesClientResult = NonNullable<AncillaryIntervalDataSubmissionQueryResponse[]>
export type PostApiTenaskaQuerySubmissionsCopClientResult = NonNullable<CopIntervalDataSubmissionQueryResponse[]>
export type PostApiTenaskaQuerySubmissionsRealtimeClientResult = NonNullable<RealTimeIntervalDataSubmissionQueryResponse[]>
export type PostApiTenaskaSubmitCopClientResult = NonNullable<TenaskaTransaction[]>
export type PostApiTenaskaSubmitRealtimeClientResult = NonNullable<TenaskaTransaction[]>
export type PostApiTenaskaSubmitAncillariesClientResult = NonNullable<TenaskaTransaction[]>
export type PostApiTenaskaVerifyClientResult = NonNullable<SubmissionStatus[]>
