/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */

export type QueryAsset = typeof QueryAsset[keyof typeof QueryAsset];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryAsset = {
  NOBLE1: 'NOBLE1',
  NOBLE2: 'NOBLE2',
} as const;
