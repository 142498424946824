import { KeyOfType } from "@app/utils";
import { AncillaryIntervalData, PriceVolume } from "@models/api/orval/schemas";
import { QueryAsset, QueryTarget } from "@models/api/queries";
import { ResourceName } from "@models/submissions";

export const unitCapacity = {
  [QueryAsset.NOBLE1] : 62.5,
  [QueryAsset.NOBLE2] : 62.5,
  NOBLESLR_BESS1 : 62.5,
  NOBLESLR_BESS2 : 62.5,
  NOBLESLR_LD1 : 62.5,
  NOBLESLR_LD2 : 62.5,
  CORALSLR_BESS1 : 48.4,
  CORALSLR_BESS2 : 52.2,
  CORALSLR_LD1 : 48.4,
  CORALSLR_LD2 : 52.2,
}

/**
 * Get the unit name of the provided asset (i.e. NOBLESLR_BESS1)
 */
export function getUnitName(asset:QueryTarget): ResourceName {
  
  switch(asset.name){
    case QueryAsset.NOBLE1:
      return asset.isLoad ? 'NOBLESLR_LD1' : 'NOBLESLR_BESS1';
    case QueryAsset.NOBLE2:
      return asset.isLoad ? 'NOBLESLR_LD2' : 'NOBLESLR_BESS2';
  }
}
/**
 * Timezone used for rendering dates and for storing dates
 */
export const uiTimezone = 'America/Chicago';

export const dateTimeFormatMoment = 'M/D/YY HH:mm (zz)';  

/**
 * Despite using Moment the Chart.JS plugin doesn't understand the timezone suffix in our normal date format
 */
export const dateTimeFormatCharts = 'M/D/YY HH:mm (CT)';

/**
 * Definition of ancillary field labels and data key names for use throughout app
 */
export const ancillaryFields : 
{
  key: KeyOfType<Required<AncillaryIntervalData>, PriceVolume>
  label: string
}[]  = [
  { key: 'rrs', label: 'RRS' },
  { key: 'regUp', label: 'Reg Up' },
  { key: 'regDown', label: 'Reg Down' },
  { key: 'ecrs', label: 'ECRS' },
  { key: 'nonSpin', label: 'Non Spin' }
]