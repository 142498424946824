/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpContext,
  HttpHeaders,
  HttpParams
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  ArchiveWithAncillary,
  DashboardWithAncillary,
  GetForecastAncillariesParams,
  GetForecastSolarBandsParams,
  SolarBand
} from '../schemas'



type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class ForecastService {
  constructor(
    private http: HttpClient,
  ) {} getForecastGetForeCastData<TData = DashboardWithAncillary[]>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/Forecast/GetForeCastData`,options
    );
  }
 getForecastAncillaries<TData = ArchiveWithAncillary[]>(
    params?: GetForecastAncillariesParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/Forecast/ancillaries`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
 getForecastSolarBands<TData = SolarBand[]>(
    params?: GetForecastSolarBandsParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/Forecast/SolarBands`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
};

export type GetForecastGetForeCastDataClientResult = NonNullable<DashboardWithAncillary[]>
export type GetForecastAncillariesClientResult = NonNullable<ArchiveWithAncillary[]>
export type GetForecastSolarBandsClientResult = NonNullable<SolarBand[]>
