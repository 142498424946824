
import { BacktestService } from "./interfaces/backtest";
import { GeneralService } from "./interfaces/general";
import { OptimizerService } from "./interfaces/optimizer";
import { ProxyService } from "./interfaces/proxy";
import { TenaskaService } from "./interfaces/tenaska";
import { TransactionsService } from "./interfaces/transactions";
import { AncillaryResourceSubmission, AncillaryResourceSubmissionOverride, AncillarySubmissionDocument, 
    AppSettings, CopResourceSubmission, CopResourceSubmissionOverride, CopSubmissionDocument, 
    EnergySubmissionDocument, IntervalHealth, RealTimeResourceSubmission, RealTimeResourceSubmissionOverride } from "./schemas";

export const providers = [
    GeneralService,
    BacktestService,
    OptimizerService,
    ProxyService,
    TenaskaService,
    TransactionsService
];

// Derived convenience types
export type ServiceType = keyof Omit<AppSettings, 'id'>;

export { ObjectBaseResourceSubmissionObjectIOptimizedSubmissionDocument as IOptimizedSubmissionDocument } from './schemas/objectBaseResourceSubmissionObjectIOptimizedSubmissionDocument';

export type AnySubmissionDocument = AncillarySubmissionDocument | CopSubmissionDocument | EnergySubmissionDocument;

export type AnyResourceSubmission = (AncillaryResourceSubmission | CopResourceSubmission | RealTimeResourceSubmission);

export type AnyOverride = AncillaryResourceSubmissionOverride | RealTimeResourceSubmissionOverride | CopResourceSubmissionOverride;

export type BatteryHealthFields = keyof Omit<IntervalHealth, 'dateTimeUtc' | 'hourEnding'>;